@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
  /* --OR: 'Overpass', sans-serif; */
 }
 @font-face {
  font-family: myFirstFont;
  src:  url("../assets/fonts/Overpass-Regular.ttf") format("truetype"),
        url("../assets/fonts/Overpass-Regular.woff") format("woff"),
        url("../assets/fonts/Overpass-Regular.woff2") format("woff2"),
        url("../assets/fonts/Overpass-VariableFont_wght.ttf") format("truetype")
        url("../assets/fonts/Overpass-Italic-VariableFont_wght.ttf") format("truetype"),
        url("../assets/fonts/Overpass-Italic.ttf") format("truetype"),
        url("../assets/fonts/Overpass-Italic.woff") format("woff"),
        url("../assets/fonts/Overpass-Italic.woff2") format("woff2");

}
  html,
  body,
  #__next {
    @apply h-full;
    
  }

  html {
    font-size: 12.5px;
  }

  * {
    font-family: myFirstFont;
    font-family: 'Overpass', Regular;
    /* font-weight: 400; */
  }
  #founding-team div img,
  nav img {
    clip-path: polygon(50% 100%, 57% 97%, 66% 97%, 72% 93%, 80% 90%, 84% 84%, 90% 79%, 93% 71%, 97% 66%, 97% 56%, 100% 49%, 97% 43%, 97% 34%, 93% 28%, 90% 20%, 84% 16%, 80% 9%, 72% 7%, 65% 2%, 58% 3%, 50% 0, 42% 3%, 34% 3%, 28% 7%, 21% 9%, 16% 16%, 10% 19%, 7% 28%, 2% 34%, 2% 43%, 0 51%, 3% 58%, 2% 65%, 7% 71%, 9% 79%, 16% 84%, 20% 90%, 28% 93%, 34% 98%, 43% 97%);
  }
  nav.fixed .h-custom,
  .custom-padding-h {
    padding: 40px 70px;
  }
  nav.fixed img.logo {
    width: 78px;
  }
  nav.fixed .h-icon {
    width: 31px;
    color: #000;
  }
  nav.fixed .h-button:hover {
    background: transparent;
  }
  nav.fixed .h-button:focus {
    outline: none;
    box-shadow: none;
  }
  .cm-bg.text-xs,
  .explore.text-xs {
    font-size: 24px;
    color: #000;
    padding: 0 10px;
    font-weight: 500;
  }
  .cm-bg.text-xs.text-white {
    color: #fff;
  }
  .explore.text-xs {
    font-weight: 400;
    white-space: nowrap;
  }
  /* .explore.text-xs span {
    z-index: 1;
  } */
  input:-internal-autofill-selected,
  input:-webkit-autofill {
    -webkit-background-clip: text;
  }
  .py-custom a.font-custom  {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 1px;
    border-bottom: 1px dotted;
  }
  .py-custom a.font-custom:hover,
  .py-custom a.custom-font22:hover {
    text-decoration: none;
  }
  .py-custom .auth-form .w-full.justify-between a.custom-font22 {
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: 1px dotted;
  }
  form.auth-form label {
    font-size: 24px;
    color: black;
    display: flex;
    align-items: center;
  }
  form.auth-form .textarea-custom label {
    flex-wrap: wrap;
  }
  form.auth-form label.l-col {
    color: #E2846F;
  }
  .policy-subtitle {
    color: #1a1a1a;
  }
  .policy-desc {
    color: #333333;
  }
  .custom-padding-h .ce-conversion-toolbar {
    display: none;
  }
  .info-mail {
    color: #000000;
    font-weight: 400;
  }
  form.auth-form input,
  .custom-font22,
  .text-xs.custom-font22  {
    font-size: 22px;
  }
  .pt-custom-150 {
    padding-top: 150px;
  }
  .p-custom-card {
    padding: 42px 32px 44px;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.2) 50%);
    transition: all ease 0.5s;
  }
  .p-custom-card > svg path {
    fill: rgba(255,255,255,0.2) !important;
  }
  .p-custom-card:hover > svg path {
    fill: #fff !important;
  }
  .p-custom-card:hover {
    background: #fff;
  }
  
  .custom-h-30 {
    display: inline-block;
    height: 30px;
    line-height: 30px;
  }

  .program-desc p + p {
    margin-top: 10px;
  }
  .program-desc p:last-child {
    font-weight: 500;
  }
  .program-desc p:last-child b {
    font-weight: 300;
  }

  /* .program-listing a:first-child .p-custom-card {
    background: #fff;
    visibility: visible;
  }
  .program-listing a:first-child .p-custom-card > svg {
    visibility: visible;
  } */
  .custom-font-37 {
    font-size: 37px;
    line-height: 1.1;
  }
  .custom-font-30 {
    font-size: 30px;
    line-height: 1.1;
    font-weight: 300;
  }
  .custom-font-24 {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 300;
  }
  .custom-font-34 {
    font-size: 34px;
    line-height: 1.2;
    font-weight: 300;
  }
  .card-bg-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  .max-w-288custom {
    max-width: 288px;
    min-height: 335px;
  }
  .p-card {
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
  .methology-wrapper .border-b + div {
    position: relative;
    z-index: 0;
  }
  .methology-wrapper .border-b + div:before {
    content: "";
    width: 100px;
    height: calc(100% + 30px);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 100%);
    position: absolute;
    left: 0px;
    top: -15px;
    display: inline-block;
    z-index: -1;
    border-radius: 15px;
    
  }
  .img-w-69 {
    width: 69px;
  }
  .flex-w-69 {
    flex: 0 0 69px;
  }
  svg.left-0 {
    --tw-translate-x: -40% !important;
  }
  svg.calender-icon {
    width: 60.6px;
    height: 66.95px;
  }
  .custom-font-18 {
    font-size: 18px;
    line-height: 1.2;
  }
  .pt-custom-150:before,
  .h-bg:before,
  .h-bg-intr:before {
    content: "";
    width: 100%;
    height: 150px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(177, 243, 246);
    z-index: 1;
  }
  .h-bg:before {
    height: 100px;
    background: rgb(252 238 220);
    z-index: 11;
  }
  .h-bg-intr:before {
    height: 100px;
  }
  .top-custom {
    top: 110px;
  }
  .custom-font-body,
  .custom-font-body.cm-bg.text-xs {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 300;
  }
  .avatarIcon-container {
    position: relative;
     z-index: 0;
  }
  .avatarIcon-container .relative {
    position: inherit;
   
  }
  .avatarIcon-container .border-b {
    border-bottom-width: 0;
  }
  .avatarIcon-container input[type="radio"]:checked {
    background-image: none;
    position: absolute;
    width: 120px;
    height: 120px;
    left: 50%;
    top: 45%;
    z-index: -1;
    transform: translate(-50%,-50%);
  }
  .cover-slider {
    width: 556px;
    padding: 0px;
    background: transparent;
    border-radius: 20px;
  }
  .cover-slider .swiper-wrapper,
  .cover-slider .swiper {
    z-index: 0;
  }

  .custom-padding-h .codex-editor__redactor {
    padding-bottom: 0 !important;
  }

  .session-journals .min-h-\[62rem\] {
    min-height: auto !important;
  }
  .session-journals .codex-editor__redactor,
  .session-journals .pb-96 {
    padding-bottom: 0 !important;
  }
  .session-journals .sticky div div {
    background-color: rgb(192, 252, 206) !important;
  }
  .session-journals div.mt-\[-9px\].w-full.bg-\[\#fceedc\] {
    background-color: rgb(192, 252, 206) !important;
  }
  .session-journals div.mt-\[-9px\].w-full.bg-\[\#fceedc\] > div {
    background-color: white;
  }
  .cover-slider video,
  .cover-slider img {
    border-radius: 20px;
  }
  .cover-slider .swiper .swiper-pagination {
    /* bottom: -5px; */
    position: initial;
  }
  @media (max-width: 800px) {
   .avatarIcon-container  img {
     width: calc(40px + (112.50 - 40) * (100vw - 375px)/(800 - 375)) !important;
     height: calc(40px + (112.50 - 40) * (100vw - 375px)/(800 - 375)) !important;
   }
   .avatarIcon-container input[type="radio"]:checked {
     width: calc(48px + (120 - 48) * (100vw - 375px)/(800 - 375)) !important;
     height: calc(48px + (120 - 48) * (100vw - 375px)/(800 - 375)) !important;
   }
   .cover-slider {
     width: calc(275px + (556 - 275) * (100vw - 375px)/(800 - 375));
   }
  }
 
  @media (max-width: 1920px) {
    nav.fixed .h-custom,
    .custom-padding-h {
    padding: calc(10px + (30) * (100vw - 375px)/(1920 - 375)) calc(0px + 70 * (100vw - 375px)/(1920 - 375));
    padding-bottom: 0;
   }
    svg.calender-icon {
      width: calc(40.6px + (20) * (100vw - 375px)/(1920 - 375));
      height: calc(40.95px + (20) * (100vw - 375px)/(1920 - 375));
    }
   .p-custom-card {
    padding: calc(22px + (20) * (100vw - 375px)/(1920 - 375)) calc(20px + (20) * (100vw - 375px)/(1920 - 375)) calc(30px + (20) * (100vw - 375px)/(1920 - 375));
   }
   nav.fixed img.logo {
    width: calc(37px + (78 - 37) * (100vw - 375px)/(1920 - 375));
   }
   nav.fixed .h-icon {
    width: calc(25px + (37 - 25) * (100vw - 375px)/(1920 - 375));
   }
   .cm-bg.text-xs,
   .explore.text-xs,
   .py-custom a.font-custom {
    font-size: calc(10px + (24 - 10) * (100vw - 375px)/(1920 - 375));
    padding: 3px calc(5px + 5 * (100vw - 375px)/(1920 - 375));
   }
   .custom-font-37 {
    font-size: calc(22px + (37 - 22) * (100vw - 375px)/(1920 - 375));
   }
   .custom-font-30,
   .custom-font-body,
   .custom-font-body.cm-bg.text-xs  {
     font-size: calc(14px + (30 - 14) * (100vw - 375px)/(1920 - 375));
   }
   .custom-font-34 {
    font-size: calc(21px + (34 - 21) * (translate-x-[-40%]100vw - 375px)/(1920 - 375));
   }
   .custom-font-24 {
     font-size: calc(14px + (24 - 14) * (100vw - 375px)/(1920 - 375));
   }
   .pt-custom-150 {
    padding-top: calc(90px + (150 - 90) * (100vw - 375px)/(1920 - 375));
   }
   .margin-top-custom {
    margin-top: calc(30px + (150 - 30) * (100vw - 375px)/(1920 - 375));
   }
   form.auth-form label {
    font-size: calc(14px + (24 - 14) * (100vw - 375px)/(1920 - 375));
   }
   .custom-h-30 {
    height: calc(20px + (10) * (100vw - 375px)/(1920 - 375));
    line-height: calc(20px + (10) * (100vw - 375px)/(1920 - 375));
   }
   form.auth-form input,
   .custom-font22,
   .text-xs.custom-font22 {
    font-size: calc(12px + (22 - 12) * (100vw - 375px)/(1920 - 375));
   }
 
  .img-w-69 {
      width: calc(45px + (69 - 45) * (100vw - 375px)/(1920 - 375));
    }
    .flex-w-69 {
      flex: 0 0 calc(45px + (69 - 45) * (100vw - 375px)/(1920 - 375));
    }
    .methology-wrapper .border-b + div:before {
      width: calc(78px + (100 - 78) * (100vw - 375px)/(1920 - 375));
    }
  .pt-custom-150:before {
    height: calc(55px + (150 - 55) * (100vw - 375px)/(1920 - 375));
  }
  .h-bg:before {
    height: calc(50px + (110 - 50) * (100vw - 375px)/(1920 - 375));
  }
  .h-bg-intr:before {
    height: calc(45px + (100 - 45) * (100vw - 375px)/(1920 - 375));
  }
  .top-custom {
    top: calc(50px + (110 - 50) * (100vw - 375px)/(1920 - 375));
  }
  }

  /* @media (min-width: 1025px) and (-webkit-device-pixel-ratio: 1.25) {
    body  {
      zoom: 65%;
    }
  }
   @media (min-width: 1025px) and (-webkit-device-pixel-ratio: 1) {
    body {
      zoom: 70%;
    }
   }
   @media (min-width: 1025px) {
    body {
      zoom: 70%;
    }
   } */
}
